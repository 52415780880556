.error-main {
  padding: 45rem 0 50rem;
  background: var(--colorBg2);
}
@media (min-width: 768px) {
  .error-main {
    padding: 80rem 0 85rem;
  }
}

.error-main__heading {
  text-align: center;
  font-size: 22rem;
  font-weight: 600;
  line-height: 1.5;
  border-bottom: 1px solid var(--colorMain2);
  padding-bottom: 16rem;
  margin-bottom: 14rem;
}
@media (min-width: 768px) {
  .error-main__heading {
    font-size: 32rem;
    border-bottom-width: 2rem;
    margin-bottom: 40rem;
  }
}

.error-main__text {
  text-align: center;
}
@media (min-width: 768px) {
  .error-main__text {
    font-size: 18rem;
    letter-spacing: 0.15em;
  }
}

.error-main__button {
  margin-top: 30rem;
  text-align: center;
}
@media (min-width: 768px) {
  .error-main__button {
    margin-top: 70rem;
  }
}